import { Component, HostBinding, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-icon-badge',
	templateUrl: 'icon-badge.component.html',
	styleUrls: ['icon-badge.component.scss']
})
export class IconBadgeComponent {
	@Input()
	icon!: string;

	@HostBinding('class')
	@Input()
	size: 'small' | 'medium' = 'small';
}
