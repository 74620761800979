import { Role, User } from '@array-app/shared/types';

/**
 * Users can control the roles included in this array
 */
export const UserControl: Role[] = ['user'];
/**
 * Admins can control the roles included in this array
 */
export const AdminControl: Role[] = [...UserControl, 'admin'];
/**
 * Owners can control the roles included in this array
 */
export const OwnerControl: Role[] = [...AdminControl, 'owner'];
/**
 * Supers can control the roles included in this array
 */
export const SuperControl: Role[] = [...OwnerControl, 'super'];

/**
 * Determines if the role provided has inherited role position of the `other` role
 * in question. E.g an admin should have all the same permissions that a user has.
 *
 * @param role the role to check with
 * @param other the role to check against
 * @returns boolean value depicting if the provided role has inherited permissions
 */
export function hasInheritedRolePermission(role: Role, other: Role) {
	switch (role) {
		case 'user':
			return UserControl.includes(other);
		case 'admin':
			return AdminControl.includes(other);
		case 'owner':
			return OwnerControl.includes(other);
		case 'super':
			return SuperControl.includes(other);
	}
}

/**
 * Determines if the currently authenticated user can perform editing
 * actions on the corresponding user
 * @param authUser The currently authenticated user
 * @param user The user to determine the editing control against
 */
export function canEditUser(authUser: User, user: User) {
	// security for malformed data
	if (user.role == null) {
		user.role = 'user';
	}

	// You cannot edit yourself unless on profile page
	if (authUser.id === user.id) {
		return false;
	}

	switch (authUser.role) {
		case 'admin':
			return AdminControl.includes(user.role);
		case 'owner':
			return OwnerControl.includes(user.role);
		case 'super':
			return SuperControl.includes(user.role);
		default:
			return false;
	}
}
