import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';

@Injectable({
	providedIn: 'root'
})
export class AppOobCodeResolve {
	constructor(
		protected readonly authService: AuthService,
		private readonly router: Router
	) {}

	async resolve(route: ActivatedRouteSnapshot) {
		const { oobCode, mode, email } = route.queryParams;

		if (oobCode) {
			try {
				await this.authService.verifyActionCode(oobCode);
			} catch {
				console.error('There was an issue with the code provided');
				this.router.navigate(['login']);
				return;
			}

			switch (mode) {
				case 'signIn': {
					const isSignInLink = await this.authService.isSignInWithEmailLink(
						window.location.href
					);
					if (!isSignInLink) {
						console.error(
							'The current url is not a proper sign in link'
						);
						this.router.navigate(['login']);
						return;
					}

					await this.authService.signInWithEmailLink(
						email,
						window.location.href
					);
					break;
				}
				default: {
					console.warn('There was no mode provided for the oob code');
					this.router.navigate(['login']);
					return;
				}
			}

			await this.authService.setupAuthUser();
		}

		return;
	}
}
