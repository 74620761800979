import { Pipe, PipeTransform } from '@angular/core';

/**
 * Used to match a string with the provided regex.
 *
 * @Usage
 * `'test' | match : /^...$/
 */
@Pipe({
	standalone: true,
	name: 'match'
})
export class StringMatchRegExpPipe implements PipeTransform {
	transform(value: string, regex: RegExp) {
		return regex.test(value);
	}
}
