import { Role } from '@array-app/shared/types';

/**
 * Array of all permissions that aren't tied 1:1 with the specific role.
 */
export const Permissions = [
	'manage-deleted-users',
	'manage-super-users'
] as const;
export type Permission = typeof Permissions[number];

/**
 * The roles included in the permission key are the able to perform that action
 */
export const PermissionMap: { [key in Permission]: Role[] } = {
	'manage-deleted-users': ['admin', 'owner', 'super'],
	'manage-super-users': ['super']
};

/**
 * Determines if a specific role has the correct permissions to do said permission.
 *
 * @param key the permissions key to check the role against
 * @param role the role to check if it's included in the permission
 * @returns boolean value depicting if the values provided result in having permissions
 */
export function hasPermission(key: Permission, role: Role) {
	return PermissionMap[key].includes(role);
}
