import { Component, HostBinding, Input } from '@angular/core';
import { isHexCode } from '@array-app/shared/utils';
import { HexCodePipe } from '../../pipes';

@Component({
	standalone: true,
	selector: 'app-hex-color',
	template: '',
	imports: [HexCodePipe],
	styleUrls: ['hex-color.component.scss']
})
export class HexColorComponent {
	@HostBinding('style.background-color')
	@Input()
	color!: string;

	@HostBinding('class.invalid-color')
	get invalidColor() {
		return !isHexCode(this.color);
	}
}
