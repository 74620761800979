import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Entity } from '@array-app/shared/types';
import { debounceTime, fromEvent, map, takeUntil } from 'rxjs';
import { DestroyService } from '../../services';

export type ValueSelect<T = Entity> = T & { value: DocumentReference<T> };

@Component({
	standalone: true,
	template: '',
	providers: [DestroyService]
})
export class SelectBaseMenuComponent<T = Entity>
	implements OnInit, AfterViewInit {
	/**
	 * Reference to the global search bar in the template
	 */
	@ViewChild('searchInput')
	readonly searchRef!: ElementRef<HTMLInputElement>;

	/**
	 * a map of already selected values that will show up as disabled in the menu
	 */
	@Input()
	selected = new Map<string, ValueSelect<T>>();

	/**
	 * Emitted when the user has selected an item in the menu
	 */
	@Output()
	valueSelect = new EventEmitter<ValueSelect<T>>();

	/**
	 * Content to be displayed in the menu
	 */
	content: ValueSelect<T>[] = [];

	/**
	 * All content in the organization
	 */
	allContent: ValueSelect<T>[] = [];

	/**
	 * `true` if there is an open request being loaded in
	 */
	loading = false;

	/**
	 * the current search value of the input
	 */
	search = '';

	constructor(protected readonly destroy$: DestroyService) {}

	ngOnInit() {
		this.fetchContent();
	}

	ngAfterViewInit() {
		this.setupListeners();
		this.searchRef.nativeElement.focus();
	}

	/**
	 * Sets up the global search listeners from input actions taken in the html.
	 */
	setupListeners() {
		fromEvent(this.searchRef.nativeElement, 'input')
			.pipe(
				takeUntil(this.destroy$),
				map((ev: any) => ev.target.value as string),
				debounceTime(250)
			)
			.subscribe((value) => {
				this.search = value;
				this.initSearch();
			});
	}

	/**
	 * Called to fetch all of the content. Left empty to use as an override
	 * in the extending component.
	 *
	 * @use
	 * ```
	 * override fetchContent() { ... }
	 * ```
	 */
	protected fetchContent() {
		// intentionally left empty
	}

	/**
	 * Called to initialize a search from the user inputted value to filter
	 * down all the content into the displayable content.
	 *
	 * @use
	 * ```
	 * override initSearch() { ... }
	 * ```
	 */
	protected initSearch() {
		// intentionally left empty
	}

	/**
	 * Invoked when the user selects one of the items in the list
	 * @param item the item that was selected
	 */
	onSelect(item: ValueSelect<T>) {
		this.valueSelect.emit(item);
	}

	/**
	 * Invoked when the search input is manually cleared
	 */
	onClear() {
		this.search = '';
		this.initSearch();
		this.searchRef.nativeElement.focus();
	}

	trackByFn(index: number, item: ValueSelect) {
		return item.id ?? index;
	}
}
