import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services';
import { API_URL_TOKEN } from '../tokens';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	readonly baseUrl = this.injector.get(API_URL_TOKEN);

	constructor(
		private readonly authService: AuthService,
		protected readonly injector: Injector
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const isLoggedIn = !!this.authService.authUser$.value;
		const isApiUrl = request.url.startsWith(this.baseUrl);

		if (isLoggedIn && isApiUrl) {
			const authToken = this.authService.getIdToken();
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${authToken}`
				}
			});
		}

		return next.handle(request);
	}
}
