import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-search-wrapper',
	imports: [CommonModule],
	templateUrl: 'search-wrapper.component.html',
	styleUrls: ['search-wrapper.component.scss']
})
export class SearchWrapperComponent {
	@Input() canClear = false;

	@Output() clear = new EventEmitter();

	onClear() {
		this.clear.emit();
	}
}
