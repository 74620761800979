import { AlgoliaUser, User, UserStatus } from '@array-app/shared/types';
import { FullNameOptions } from './full-name-options.type';
import { StatusOptions } from './user-status-options.type';

/**
 * Generates the corresponding users name. You can pass configurations to determine how
 * the users name should be returned based on the context.
 *
 * @param user the user to generate the name of
 * @param options object of configurable data to determine how the name string should be returned
 * @returns a string depicting the users name
 */
export function fullName(
	user: Pick<User, 'lastName' | 'firstName'>,
	options?: FullNameOptions
) {
	if (!user) {
		return '';
	}

	if (options?.lastFirst) {
		return `${user.lastName}, ${user.firstName}`;
	}
	return `${user.firstName} ${user.lastName}`;
}

/**
 * Generates a users initials based on their naming data.
 *
 * @param user the user to generate the initials of
 * @returns a string depicting the users initials
 */
export function initials(user: User | AlgoliaUser) {
	let value = '--';
	if (user) {
		if ((user as AlgoliaUser).name) {
			const split = (user as AlgoliaUser).name.split(' ');
			if (split.length > 1) {
				const first = split[0].slice(0, 1) ?? '';
				const last = split[split.length - 1].slice(0, 1) ?? '';
				value = first + last;
			} else if (split.length === 1) {
				value = split[0].slice(0, 1) ?? '';
			}
		} else {
			const first = (user as User).firstName?.slice(0, 1) ?? '';
			const last = (user as User).lastName?.slice(0, 1) ?? '';

			if (first || last) {
				value = first + last;
			}
		}
	}

	return value.toUpperCase();
}

/**
 * Determines what the corresponding user's status is in the system based on
 * the users current data and timestamps.
 *
 * @param user the user to determine the status of
 * @returns a string depicting the current status of the user in the system
 */
export function status(user: User, options?: StatusOptions): UserStatus {
	if (user.deletedAt && options?.includeDeletion) {
		return 'deleted';
	} else if (user.disabledAt) {
		return 'disabled';
	} else if (user.rejectedAt) {
		return 'rejected';
	} else if (!user.authId) {
		return 'pending';
	}

	return 'active';
}
