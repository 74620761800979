import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PageTitleResolve {
	readonly pageTitleBase = 'Array | ';

	constructor(protected readonly translate: TranslateService) {}

	/**
	 * `async` is needed for extending classes
	 */
	async resolve(route: ActivatedRouteSnapshot) {
		document.title = `${this.pageTitleBase}${this.translate.instant(
			route.data['title'] || 'route.title.portal'
		)}`;
		return document.title;
	}
}
