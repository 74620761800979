import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
	/**
	 * @param size the size of the file bytes
	 */
	transform(size: number) {
		return this.formatBytes(size);
	}

	formatBytes(bytes: number, decimals: number = 2) {
		if (bytes === 0) {
			return '0 bytes';
		}

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return (
			parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
		);
	}
}
