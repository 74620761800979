import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'formatTimestamp'
})
export class FormatTimestampPipe implements PipeTransform {
	constructor(private readonly datePipe: DatePipe) {}

	transform(value: any, format = 'medium') {
		const date = new Date((value.seconds || value._seconds) * 1000);
		return this.datePipe.transform(date, format);
	}
}
