import { DisplayTheme, Theme } from '@array-app/shared/types';

/**
 * Sets the app to the provided theme
 * @param theme The new theme to set the app to
 */
export function setTheme(theme: Theme) {
	let setTheme = theme;
	if (theme === 'auto') {
		setTheme = getAutoTheme();
	}

	localStorage.setItem('theme', theme);
	document.body.setAttribute('theme', setTheme);
}

/**
 * Returns what the currently set theme is
 * @returns the currently set theme
 */
export function getTheme(): Theme {
	let theme = localStorage.getItem('theme') as any;
	if (theme == null || theme === 'null') {
		theme = 'auto';
	}
	return theme;
}

/**
 * Returns the currently active display theme based on the body attribute.
 * @returns the display theme
 */
export function getDisplayTheme(): DisplayTheme {
	return document.body.getAttribute('theme') as DisplayTheme;
}

/**
 * Removes the specific theme from the body element
 */
export function removeTheme() {
	localStorage.removeItem('theme');
	document.body.removeAttribute('theme');
}

/**
 * Determines what the auto theme is based on the browser preference
 * @returns the theme detected based on the browser preference
 */
export function getAutoTheme(): DisplayTheme {
	if (window.matchMedia) {
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			return DisplayTheme.Dark;
		} else {
			return DisplayTheme.Light;
		}
	}

	return DisplayTheme.Light;
}
