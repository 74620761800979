<p class="label" [title]="label">
	{{ label }}

	<span *ngIf="info" class="margin-left-1" [matTooltip]="info">
		<i class="fa-solid fa-circle-info"></i>
	</span>
</p>
<div class="value">
	<ng-container *ngIf="!slotting; else slot">
		<ng-container *ngIf="!!value; else noValue">
			<ng-container *ngIf="richText">
				<quill-view-html [content]="value"></quill-view-html>
			</ng-container>
			<ng-container *ngIf="!richText">
				<p [title]="value">{{ value }}</p>
			</ng-container>
		</ng-container>
	</ng-container>
</div>

<ng-template #noValue>
	<p class="no-value" [title]="'form.shared.no-value' | translate">
		{{ 'form.shared.no-value' | translate }}
	</p>
</ng-template>

<ng-template #slot>
	<ng-content></ng-content>
</ng-template>
