import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
	Auth,
	User as FirebaseUser,
	checkActionCode,
	isSignInWithEmailLink,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithEmailLink,
	updatePassword
} from '@angular/fire/auth';
import {
	CollectionReference,
	Firestore,
	Timestamp,
	addDoc,
	collection,
	doc,
	getDoc,
	updateDoc
} from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	AuthUser,
	AuthUserOrgUser,
	LoginAttempt,
	Organization,
	User
} from '@array-app/shared/types';
import { sanitize } from '@array-app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_URL_TOKEN } from '../tokens';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	/**
	 * `true` if the user is apart of multiple organizations
	 */
	isMultiOrg = false;
	/**
	 * The auth user from Firebase Auth.
	 */
	readonly firebaseUser$ = new BehaviorSubject<FirebaseUser | undefined>(
		undefined
	);
	/**
	 * The users higher level information containing ids of all their users
	 * spanning across multiple orgs
	 */
	readonly authUser$ = new BehaviorSubject<AuthUser | undefined>(undefined);
	/**
	 * The auth users id token to append to headers for api calls
	 */
	private authIdToken: string | undefined;
	/**
	 * The organization that the user is currently signed in under
	 */
	readonly organization$ = new BehaviorSubject<Organization | undefined>(
		undefined
	);
	/**
	 * An observable from the user data in the Firebase Firestore.
	 * Will be updated as the document is updated
	 */
	readonly user$ = new BehaviorSubject<User | undefined>(undefined);
	/**
	 * The base url for the current environment
	 */
	private readonly baseUrl = this.injector.get(API_URL_TOKEN);

	/**
	 * returns `true` if the current authUser is a super user in the system
	 */
	private get isSuper() {
		const role = this.authUser$.value?.role;
		if (role && role === 'super') {
			return true;
		} else {
			return false;
		}
	}

	constructor(
		private readonly auth: Auth,
		private readonly firestore: Firestore,
		private readonly http: HttpClient,
		private readonly snackbar: MatSnackBar,
		private readonly translate: TranslateService,
		private readonly injector: Injector
	) {}

	/**
	 * Sets up the authenticated user with firebase data, auth data, and org specific data
	 */
	async setupAuthUser(
		organizationId: string | null = localStorage.getItem('orgId')
	) {
		if (this.firebaseUser$.value && this.authUser$.value) {
			return;
		}

		const firebaseUser = this.auth.currentUser;
		if (firebaseUser) {
			this.firebaseUser$.next(firebaseUser);
			this.authIdToken = await firebaseUser?.getIdToken();

			const value = await getDoc(
				doc(
					collection(
						this.firestore,
						'authUsers'
					) as CollectionReference<AuthUser>,
					firebaseUser.uid
				)
			);
			const authUser = value?.data();
			if (authUser) {
				authUser.users = authUser.users.filter(
					(user) => !user.deletedAt && !user.disabledAt
				);
				this.authUser$.next(authUser);
				// we need to await this function to make sure the organization is setup before this
				// function finishes
				await this.determineOrganization(organizationId);
			}
		}
	}

	/**
	 * Determines what organization the auth user will be signed into
	 */
	async determineOrganization(
		organizationId: string | null = localStorage.getItem('orgId')
	) {
		const authUser = this.authUser$.value;
		if (!authUser) {
			console.error('Cannot determine org, no auth user was found.');
			return;
		}

		let selectedUser!: undefined | AuthUserOrgUser;

		if (organizationId) {
			selectedUser = authUser.users.find(
				(user) => user.organizationId === organizationId
			);
		}

		// if selected user wasn't preset
		if (!selectedUser) {
			if (authUser.users.length > 1) {
				// ... ask which org to go to
				this.isMultiOrg = true;

				// TODO: update with a user selection instead of setting to index 0
				selectedUser = authUser.users[0];
			} else if (authUser.users.length === 1) {
				this.isMultiOrg = false;
				selectedUser = authUser.users[0];
			}
		}

		// if selected user wasn't found on the current auth user
		if (!selectedUser) {
			this.snackbar.open(
				this.translate.instant('users.no-orgs-available', {
					email: authUser.email
				})
			);
			this.logout();
			console.warn(
				'There are no organizations associated with this auth user'
			);
			throw 'No organizations available';
		}

		await getDoc(
			doc(
				collection(
					this.firestore,
					`organizations/${selectedUser.organizationId}/users`
				) as CollectionReference<User>,
				selectedUser.id
			)
		).then((value) => {
			const user = value?.data();
			if (user) {
				if (this.isSuper) {
					user.role = 'super';
				}
				this.user$.next(user);
			}
		});

		await getDoc(
			doc(
				collection(
					this.firestore,
					`organizations`
				) as CollectionReference<Organization>,
				selectedUser.organizationId
			)
		).then((value) => {
			const org = value?.data();
			if (org) {
				this.organization$.next(org);
			}
		});

		// Saves selected user preference so we know where to route the user to based on previous actions
		localStorage.setItem('userId', selectedUser.id);
		localStorage.setItem('orgId', selectedUser.organizationId);
	}

	/**
	 * Fetches the currently authenticated user data from the currently selected organization
	 * @returns The organization specific user data
	 */
	async getUser() {
		// By grabbing the userId from local storage we can quickly grab the user without hitting auth user collection
		let userId = localStorage.getItem('userId');
		let org = localStorage.getItem('orgId');

		if (!userId || !org) {
			const firebaseUser = this.firebaseUser$.value;
			if (firebaseUser) {
				const authUser = (
					await getDoc(
						doc(
							collection(
								this.firestore,
								'authUsers'
							) as CollectionReference<AuthUser>,
							firebaseUser.uid
						)
					)
				).data() as AuthUser;
				this.authUser$.next(authUser);
				// just grab the first user in the authUsers array
				userId = authUser.users[0].id;
				org = authUser.users[0].organizationId;

				localStorage.setItem('userId', userId);
				localStorage.setItem('orgId', org);
			} else {
				console.error('No authenticated user found');
				return;
			}
		}

		if (this.user$.value) {
			return this.user$.value;
		} else {
			const user = (
				await getDoc(
					doc(
						collection(
							this.firestore,
							`organizations/${org}/users`
						),
						userId
					)
				)
			).data() as User;

			if (this.isSuper) {
				user.role = 'super';
			}

			this.user$.next(user);
			return user;
		}
	}

	/**
	 * Retrieves the auth id token generated from firebase
	 */
	getIdToken() {
		return this.authIdToken;
	}

	/**
	 * Switches the user to organization corresponding to the provided id
	 * @param id The id of the organization to switch to
	 */
	async switchToOrganizationById(id: string, reload = true) {
		localStorage.setItem('orgId', id);

		if (this.user$.value) {
			await this.trackLoginAttempt(this.user$.value.email, true, id);
		}

		// todo: find more intuitive approach
		if (reload) {
			window.location.reload();
		}
	}

	/**
	 * Attempts to log the current user with the provided information
	 * @param email The email provided by the user to log in with
	 * @param password The password provided by the user to log in with
	 */
	async login(email: string, password: string) {
		try {
			await signInWithEmailAndPassword(this.auth, email, password);
			// Track the general app login attempt, the org level will be in the "switchOrganizationById" logic
			this.trackLoginAttempt(email, true);
		} catch (error) {
			this.trackLoginAttempt(email, false);
			throw error;
		}
	}

	/**
	 * Logs out the currently authenticated user
	 */
	async logout() {
		localStorage.removeItem('orgId');
		localStorage.removeItem('userId');
		this.organization$.next(undefined);
		this.user$.next(undefined);
		this.authUser$.next(undefined);
		this.firebaseUser$.next(undefined);
		await this.auth.signOut();
	}

	/**
	 * Initiates the forgot password workflow with the email provided
	 * @param email the email to reset the  password of
	 */
	async forgotPassword(email: string) {
		await sendPasswordResetEmail(this.auth, email);
	}

	/**∏
	 * Updates the firebase user password to the new provided one
	 * @param password the new password to update the firebase user to
	 */
	async updatePassword(password: string) {
		const firebaseUser = this.firebaseUser$.value;
		if (firebaseUser) {
			return updatePassword(firebaseUser, password);
		}
		throw new Error('Firebase user not found');
	}

	/**
	 * Creates a new auth user in the system
	 * @param email The email to create the user with
	 * @param password The password to create the user with
	 * @returns The newly created user
	 */
	create(
		email: string,
		password: string,
		userId: string,
		organizationId: string
	): Observable<AuthUser> {
		return this.http.post<AuthUser>(
			`${this.baseUrl}/v1/organizations/${organizationId}/users/auth`,
			{
				email,
				password,
				userId
			}
		);
	}

	/**
	 * Updates the currently authenticated user with the corresponding data
	 * @param data The data to update the user with
	 * @returns The updated user
	 */
	async update(data: Partial<User>) {
		if (!this.organization$.value) {
			throw new Error('No organization found for this auth user');
		} else if (!this.user$.value) {
			throw new Error(
				'No organization user data found for this auth user'
			);
		}

		const userCollection = collection(
			this.firestore,
			`organizations/${this.organization$.value.id}/users`
		) as CollectionReference<User>;

		const snapshot = await getDoc(
			doc(userCollection, this.user$.value.id)
		).catch((error) => {
			console.error(error);
			throw new Error('User record not found');
		});

		const oldUser = snapshot.data();
		if (oldUser) {
			data.email = oldUser.email;
			data.updatedAt = Timestamp.now();
		}

		await updateDoc(snapshot.ref, sanitize(data)).catch((error) => {
			console.error(error);
			throw new Error('There was an error updating the users profile');
		});

		const user = (
			await getDoc(doc(userCollection, this.user$.value.id)).catch(
				(error) => {
					console.error(error);
					throw new Error(
						'There was an issue refetching the user profile data after updating'
					);
				}
			)
		).data() as User;

		if (this.isSuper) {
			user.role = 'super';
		}

		this.user$.next(user);
		return user;
	}

	/**
	 * Verifies that the code provided is still active and what information is
	 * associated with the code.
	 * @param code The code to verify the action of
	 * @returns metadata associated with the code provided
	 */
	async verifyActionCode(code: string) {
		return checkActionCode(this.auth, code);
	}

	/**
	 * Determines if the link passed through can be used as a way to sign a user in
	 * @param link The url link to check
	 * @returns a boolean value representing if the url can be used as a sign in link from an email
	 */
	async isSignInWithEmailLink(link: string) {
		return isSignInWithEmailLink(this.auth, link);
	}

	/**
	 * Signs a user into the app based on an email generated from their email
	 * @param email the email to sign in with
	 * @param link the url to pull the needed data off of in order to sign a user in without a password
	 * @returns firebase user credentials from the user who was signed in
	 */
	async signInWithEmailLink(email: string, link: string) {
		return signInWithEmailLink(this.auth, email, link);
	}

	/**
	 * Tracks the login attempt into firebase for audit tracking
	 * @param email the email attempting to login
	 * @param successful `true` if the user was able to successfully login
	 */
	trackLoginAttempt(
		email: string,
		successful: boolean,
		organizationId?: string
	) {
		const attempt: LoginAttempt = {
			email,
			successful,
			createdAt: Timestamp.now(),
			userAgent: navigator.userAgent,
			platform: 'portal'
		};

		try {
			if (organizationId) {
				return addDoc(
					collection(
						this.firestore,
						`organizations/${organizationId}/loginAttempts`
					),
					attempt
				);
			} else {
				return addDoc(
					collection(this.firestore, 'loginAttempts'),
					attempt
				);
			}
		} catch (error) {
			console.error(
				'There was an issue tracking the login attempt',
				error
			);
		}

		return Promise.resolve({});
	}
}
