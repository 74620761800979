import { Component, HostBinding, Input } from '@angular/core';
import { Status, StatusType } from '@array-app/shared/types';

@Component({
	standalone: true,
	selector: 'app-loading-spinner',
	templateUrl: 'loading-spinner.component.html',
	styleUrls: ['loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
	@HostBinding('class')
	@Input()
	type: StatusType = Status.Brand;

	@HostBinding('attr.size')
	@Input()
	size: 'xsmall' | 'small' | 'medium' | 'large' = 'medium';
}
