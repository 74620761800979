import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusType } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';

@Component({
	standalone: true,
	selector: 'app-label-value',
	imports: [CommonModule, TranslateModule, QuillModule, MatTooltipModule],
	templateUrl: 'label-value.component.html',
	styleUrls: ['label-value.component.scss']
})
export class LabelValueComponent {
	@Input() label!: string;
	@Input() value: string | null | undefined = undefined;

	@Input() info: string | null | undefined = undefined;

	/**
	 * The color that the label should render as
	 */
	@HostBinding('attr.color')
	@Input()
	color: StatusType = 'chromatic';

	/**
	 * `true` if the value being inputted should be rendered as rich text
	 */
	@Input() richText = false;

	/**
	 * `true` if the value being added should be slotted instead
	 * of being passed through the `value` property
	 */
	@HostBinding('class.slotting')
	@Input()
	slotting = false;

	@HostBinding('class.wrap')
	@Input()
	wrap = false;
}
