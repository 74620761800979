import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	standalone: true,
	name: 'relativeTimestamp'
})
export class RelativeTimestampPipe implements PipeTransform {
	constructor(private readonly translate: TranslateService) {}
	/**
	 * @param value The UTC String of the time to be relative to
	 * @returns A relative time descriptor
	 */
	transform(timestamp: Timestamp, future = false): string {
		const current = new Date().valueOf();
		const input = timestamp.toDate().valueOf();

		const msPerMinute = 60 * 1000;
		const msPerHour = msPerMinute * 60;
		const msPerDay = msPerHour * 24;
		const msPerMonth = msPerDay * 30;
		const msPerYear = msPerDay * 365;

		const elapsed = current - input;
		const delta = future ? Math.abs(elapsed) : elapsed;

		let unit = null;
		let value = null;

		if (delta < msPerMinute) {
			unit = null;
			value = null;
		} else if (delta < msPerHour) {
			value = Math.round(delta / msPerMinute);
			unit = 'minute';
		} else if (delta < msPerDay) {
			value = Math.round(delta / msPerHour);
			unit = 'hour';
		} else if (delta < msPerMonth) {
			value = Math.round(delta / msPerDay);
			unit = 'day';
		} else if (delta < msPerYear) {
			value = Math.round(delta / msPerMonth);
			unit = 'month';
		} else {
			value = Math.round(delta / msPerYear);
			unit = 'year';
		}

		if (value && unit) {
			unit += value > 1 ? 's' : '';
		}

		if (unit && value) {
			if (elapsed < 0 && future) {
				return this.translate.instant('time.relative-future-time', {
					value,
					unit
				});
			}

			return this.translate.instant('time.relative-time-ago', {
				value,
				unit
			});
		} else {
			if (future) {
				return this.translate.instant('time.relative-future-time', {
					value: '<',
					unit: '1'
				});
			}

			return this.translate.instant('time.now');
		}
	}
}
