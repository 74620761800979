import {
	ExternalLink,
	Group,
	Organization,
	Product,
	Report,
	Tag,
	User
} from '@array-app/shared/types';
import { fullName } from '../user';

export function userSearch(
	users: Pick<
		User,
		'email' | 'role' | 'position' | 'division' | 'firstName' | 'lastName'
	>[],
	search: string
) {
	search = search.trim().toLowerCase();

	return users.filter((user) => {
		return (
			fullName(user)?.toLowerCase().includes(search) ||
			user.email.toLowerCase().includes(search) ||
			user.role.toLowerCase().includes(search) ||
			user.position?.toLowerCase().includes(search) ||
			user.division?.toLowerCase().includes(search)
		);
	}) as User[];
}

export function organizationSearch(
	organizations: Organization[],
	search: string
) {
	search = search.trim().toLowerCase();

	return organizations.filter((org: Organization) => {
		return org.name?.toLowerCase().includes(search);
	});
}

export function productSearch(products: Product[], search: string) {
	search = search.trim().toLowerCase();

	return products.filter((product: Product) => {
		return product.name?.toLowerCase().includes(search);
	});
}

export function tagSearch(tags: Tag[], search: string) {
	search = search.trim().toLowerCase();

	return tags.filter((tag: Tag) => {
		return tag.name?.toLowerCase().includes(search);
	});
}

export function groupSearch(groups: Pick<Group, 'name'>[], search: string) {
	search = search.trim().toLowerCase();

	return groups.filter((group) => {
		return group.name?.toLowerCase().includes(search);
	}) as Group[];
}

export function reportSearch(reports: Report[], search: string) {
	search = search.trim().toLowerCase();

	return reports.filter((report: Report) => {
		return report.name?.toLowerCase().includes(search);
	});
}

export function externalLinkSearch(links: ExternalLink[], search: string) {
	search = search.trim().toLowerCase();

	return links.filter((link: ExternalLink) => {
		return link.name?.toLowerCase().includes(search);
	});
}
