import { Component, HostBinding, Input } from '@angular/core';
import { Status, StatusType } from '@array-app/shared/types';

@Component({
	standalone: true,
	selector: 'app-text-badge',
	templateUrl: 'text-badge.component.html',
	styleUrls: ['text-badge.component.scss']
})
export class TextBadgeComponent {
	@HostBinding('class')
	@Input()
	type: StatusType = Status.Info;
}
