<button
	class="icon-only"
	[class.filter-active]="activeFilters.size"
	title="Filter"
	[matMenuTriggerFor]="filterMenu"
>
	<i class="fa-solid fa-filter"></i>
	<sup class="superscript">{{ activeFilters.size }}</sup>
</button>

<mat-menu class="filter-menu" #filterMenu="matMenu">
	<ng-template matMenuContent>
		<div class="container">
			@for (option of options; track option) { @if
			(getAsFilterGroup(option).filters) {
			<button class="link secondary">{{ option.label }}</button>
			@for ( filter of getAsFilterGroup(option).filters; track filter ) {
			<mat-checkbox
				(click)="$event.stopPropagation()"
				(change)="onOptionSelected($event, filter)"
				[checked]="activeFilters.has(filter.key)"
			>
				{{ filter.label }}
			</mat-checkbox>
			} } @else {
			<mat-checkbox
				(click)="$event.stopPropagation()"
				(change)="onOptionSelected($event, getAsFilter(option))"
				[checked]="activeFilters.has(getAsFilter(option).key)"
			>
				{{ option.label }}
			</mat-checkbox>
			} }
		</div>

		<div class="footer">
			<button class="secondary small" (click)="onClear()">Clear</button>
			<button class="primary small" (click)="onApply()">Apply</button>
		</div>
	</ng-template>
</mat-menu>
