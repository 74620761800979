import { Component, EventEmitter, Input } from '@angular/core';
import {
	MatCheckboxChange,
	MatCheckboxModule
} from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';

type Filter = {
	/**
	 * The label to display depicting what the filter option is for
	 */
	label: string;

	/**
	 * The key to track this filter option with. Should be unique to the other filter options
	 */
	key: string;

	/**
	 * The handler will go off once the option has been selected and applied
	 */
	handler?: () => {};
};

type FilterGroup = {
	/**
	 * The label to display as the group name
	 */
	label: string;

	/**
	 * The filter options to select from within this group
	 */
	filters: Filter[];
};

export type Filters = (FilterGroup | Filter)[];

@Component({
	standalone: true,
	selector: 'app-filter',
	imports: [MatMenuModule, MatCheckboxModule],
	templateUrl: 'filter.component.html',
	styleUrls: ['filter.component.scss']
})
export class FilterComponent {
	@Input()
	options: Filters = [];

	@Input()
	activeFilters = new Set<string>();

	change = new EventEmitter<Set<string>>();

	onOptionSelected(change: MatCheckboxChange, option: Filter) {
		if (change.checked) {
			this.activeFilters.add(option.key);
		} else {
			this.activeFilters.delete(option.key);
		}
	}

	// onGroupSelected() {
	// 	if (change.checked) {
	// 		this.activeFilters.add(option.key);
	// 	} else {
	// 		this.activeFilters.delete(option.key);
	// 	}
	// }

	onApply() {
		this.change.emit(this.activeFilters);
	}

	onClear() {
		this.activeFilters = new Set();
		this.onApply();
	}

	getAsFilterGroup(group: FilterGroup | Filter): FilterGroup {
		return group as FilterGroup;
	}

	getAsFilter(filter: FilterGroup | Filter): Filter {
		return filter as Filter;
	}
}
