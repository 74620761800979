import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StripHtmlTagPipe } from '../../pipes';

@Component({
	standalone: true,
	selector: 'app-title-description',
	imports: [StripHtmlTagPipe, TranslateModule, CommonModule],
	templateUrl: 'title-description.component.html',
	styleUrls: ['title-description.component.scss']
})
export class TitleDescriptionComponent {
	@Input() title!: string;
	@Input() description!: string | null | undefined;

	/**
	 * `true` if the description should be visual shown as error/danger text
	 */
	@Input() descriptionError = false;
}
