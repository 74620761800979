<h1 mat-dialog-title>{{ data.header }}</h1>

<div mat-dialog-content>
	<p>{{ data.message }}</p>
</div>

<div mat-dialog-actions>
	<button class="primary small" (click)="acknowledge()">
		{{ 'generic.ok' | translate }}
	</button>
</div>
