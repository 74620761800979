/**
 * RegExp for at least 8 characters, 1 letter, 1 number, and 1 special character
 */
export const RegExpEightCharactersOneLetterOneNumberOneSpecial = /^(?=.*[A-Za-z])(?=.*?[0-9])(?=.*?[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|\\]).{8,}$/;

/**
 * RegExp for at least 8 characters
 */
export const RegExpEightCharacters = /^.{8,}$/;

/**
 * RegExp for at least 1 letter character
 */
export const RegExpOneLetter = /^.*[A-Za-z].*$/;

/**
 * RegExp for at least 1 number character
 */
export const RegExpOneNumber = /^.*[0-9].*$/;

/**
 * RegExp for at least 1 special character
 */
export const RegExpOneSpecialCharacter = /^.*[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|\\].*$/;

/**
 * Checks if the provided password is considered a valid and safe password from within
 * our system.
 *
 * @param password password to validate
 * @returns boolean value depicting the password validity
 */
export function isValidPassword(password: string) {
	return RegExpEightCharactersOneLetterOneNumberOneSpecial.test(password);
}
