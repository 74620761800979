import { Pipe, PipeTransform } from '@angular/core';
import { isHexCode } from '@array-app/shared/utils';

@Pipe({
	standalone: true,
	name: 'isHexCode'
})
export class HexCodePipe implements PipeTransform {
	transform(value: string) {
		return isHexCode(value);
	}
}
