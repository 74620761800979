export enum Status {
	Success = 'success',
	Danger = 'danger',
	Caution = 'caution',
	Info = 'info',
	Brand = 'brand',
	Chromatic = 'chromatic'
}

export type StatusType =
	| 'success'
	| 'danger'
	| 'caution'
	| 'info'
	| 'brand'
	| 'chromatic';
