import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	standalone: true,
	name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
	constructor(private readonly translate: TranslateService) {}
	/**
	 * @param value The UTC String of the time to be relative to
	 * @returns A relative time descriptor
	 */
	transform(value: string): string {
		const current = new Date().valueOf();
		const input = new Date(value).valueOf();
		const msPerMinute = 60 * 1000;
		const msPerHour = msPerMinute * 60;
		const msPerDay = msPerHour * 24;
		const msPerMonth = msPerDay * 30;
		const msPerYear = msPerDay * 365;

		const elapsed = current - input;
		let timeUnit = null;
		let timeValue = null;

		if (elapsed < msPerMinute) {
			timeUnit = null;
			timeValue = null;
		} else if (elapsed < msPerHour) {
			timeValue = Math.round(elapsed / msPerMinute);
			timeUnit = 'm';
		} else if (elapsed < msPerDay) {
			timeValue = Math.round(elapsed / msPerHour);
			timeUnit = 'h';
		} else if (elapsed < msPerMonth) {
			timeValue = Math.round(elapsed / msPerDay);
			timeUnit = 'd';
		} else if (elapsed < msPerYear) {
			timeValue = Math.round(elapsed / msPerMonth);
			timeUnit = 'm';
		} else {
			timeValue = Math.round(elapsed / msPerYear);
			timeUnit = 'y';
		}

		if (timeUnit && timeValue) {
			return this.translate.instant('time.relative-time-ago', {
				value: timeValue,
				unit: timeUnit
			});
		} else {
			return this.translate.instant('time.now');
		}
	}
}
