/**
 * Downloads the csv
 * @param data The data to save as a csv
 * @param name The name of the file.
 */
export function downloadCsv(data: string, name = 'download') {
	const tempLink = document.createElement('a');
	tempLink.href = `data:text/csv;charset=utf-8,${data}`;
	tempLink.target = '_blank';
	tempLink.download = `${name}.csv`;
	tempLink.click();
}
