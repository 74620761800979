import { Component, Inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogRole } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-confirm-dialog',
	imports: [TranslateModule, MatDialogModule],
	templateUrl: 'confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
	constructor(
		private readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public readonly data: { header: string; message: string }
	) {}

	onConfirm() {
		this.dialogRef.close({ role: DialogRole.Submit });
	}

	onCancel() {
		this.dialogRef.close({ role: DialogRole.Cancel });
	}
}
