import { Paginated, PaginatedOptions } from '@array-app/shared/types';

/**
 * Initializes a paginated object with the preferred initial data.
 *
 * @param options optional override options to initialize the pagination with
 * @returns paginated object with initial data
 */
export function initialPagination<T>(
	options: PaginatedOptions<T> = {
		sortDirection: 'ASC',
		search: ''
		// sortBy: 'name'
	}
): Paginated<T> {
	return {
		loading: false,
		size: 0,
		content: [],
		options
	};
}
