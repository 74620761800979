// Allows for valid 3 or 6 digit hex codes
export const HEX_CODE_REG_EX = /^#([0-9a-f]{3}){1,2}$/i;

/**
 * Determines if the value provided is a valid hex code or not
 * @param value the value to test
 * @returns boolean depicting if the value is a hex or not
 */
export function isHexCode(value: string) {
	return HEX_CODE_REG_EX.test(value);
}

/**
 * Converts any hex code into an hsl value
 * @param hex The hex code to convert
 * @returns the newly generated hsl value
 */
export function hexToHSL(hex: string) {
	// Convert hex to RGB first
	let r: any = 0;
	let g: any = 0;
	let b: any = 0;
	if (hex.length == 4) {
		r = '0x' + hex[1] + hex[1];
		g = '0x' + hex[2] + hex[2];
		b = '0x' + hex[3] + hex[3];
	} else if (hex.length == 7) {
		r = '0x' + hex[1] + hex[2];
		g = '0x' + hex[3] + hex[4];
		b = '0x' + hex[5] + hex[6];
	}
	// Then to HSL
	r /= 255;
	g /= 255;
	b /= 255;

	const cmin = Math.min(r, g, b);
	const cmax = Math.max(r, g, b);
	const delta = cmax - cmin;
	let h = 0;
	let s = 0;
	let l = 0;

	if (delta == 0) h = 0;
	else if (cmax == r) h = ((g - b) / delta) % 6;
	else if (cmax == g) h = (b - r) / delta + 2;
	else h = (r - g) / delta + 4;

	h = Math.round(h * 60);

	if (h < 0) h += 360;

	l = (cmax + cmin) / 2;
	s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
	s = +(s * 100).toFixed(1);
	l = +(l * 100).toFixed(1);

	return {
		h,
		s,
		l
	};
}
