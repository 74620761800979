import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
	constructor(private readonly datePipe: DatePipe) {}

	transform(value: Date) {
		return this.datePipe.transform(value, 'MM/dd/yyyy');
	}
}
