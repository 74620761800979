<h1 mat-dialog-title>{{ data.header }}</h1>

<div mat-dialog-content>
	<p>{{ data.message }}</p>
</div>

<div mat-dialog-actions>
	<button class="secondary small" (click)="onCancel()">
		{{ 'generic.cancel' | translate }}
	</button>
	<button class="primary small" (click)="onConfirm()">
		{{ 'generic.confirm' | translate }}
	</button>
</div>
