import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import {
	CollectionReference,
	DocumentData,
	DocumentReference,
	Firestore,
	Query,
	Timestamp,
	UpdateData,
	addDoc,
	arrayRemove,
	arrayUnion,
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where
} from '@angular/fire/firestore';
import { API_URL_TOKEN, AuthService } from '@array-app/frontend/authentication';
import { Entity } from '@array-app/shared/types';
import { sanitize } from '@array-app/shared/utils';
import { Observable, ReplaySubject, combineLatest, takeUntil } from 'rxjs';
import { COLLECTION_NAME_TOKEN } from '../tokens';

export interface BaseApiService<T extends Entity> {
	/**
	 * Creates a new entity utilizing a rest endpoint instead of the usual Firebase method.
	 * This may need to be used for complex entities that require much more underlying logic
	 * that was better suited for backend abstraction.
	 *
	 * @param data the data to create the new entity with
	 */
	create$?(data: Partial<T>): Observable<T> | void;

	/**
	 * Updates an existing entity utilizing a rest endpoint instead of the usual Firebase method.
	 * This may need to be used for complex entities that require much more underlying logic
	 * that was better suited for backend abstraction.
	 *
	 * @param data the data to update the new entity with
	 */
	update$?(data: Partial<T>): Observable<T> | void;

	/**
	 * Deletes an existing entity utilizing a rest endpoint instead of the usual Firebase method.
	 * This may need to be used for complex entities that require much more underlying logic
	 * that was better suited for backend abstraction.
	 *
	 * @param id the id of the data to mark as deleted
	 */
	delete$?(id: string): Observable<T> | void;
}

@Injectable()
export abstract class BaseApiService<T extends Entity> implements OnDestroy {
	/**
	 * The entire collection path including the baseCollectionPath
	 */
	organizationId: string | undefined = undefined;

	/**
	 * Reference to the collection
	 */
	collection!: CollectionReference<T, T>;

	/**
	 * Reference to the basic query collection reference for available docs
	 */
	collectionQuery!: Query<T>;

	/**
	 * The base url set by the environment
	 */
	public readonly baseUrl = this.injector.get(API_URL_TOKEN);

	/**
	 * Used to attach to subscription so the subscription will tear down themselves
	 * once the service has been destroyed.
	 */
	private readonly destroy$ = new ReplaySubject<void>();

	/**
	 * The generated url that each api service will prefix their http requests with
	 */
	get url() {
		if (this.organizationId) {
			return `${this.baseUrl}/v1/organizations/${this.organizationId}`;
		}
		return `${this.baseUrl}/v1`;
	}

	get data() {
		return getDocs(this.collectionQuery).then((snapshot) =>
			snapshot.docs.map((d) => d.data())
		);
	}

	constructor(
		protected readonly firestore: Firestore,
		protected readonly authService: AuthService,
		protected readonly injector: Injector,
		@Inject(COLLECTION_NAME_TOKEN)
		protected readonly collectionName?: string
	) {
		combineLatest([
			this.authService.organization$,
			this.authService.authUser$
		])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([org, user]) => {
				if (user && org) {
					this.organizationId = org.id;
					this.initialize(this.organizationId);
				} else {
					this.reset();
				}
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Used to reset the service back to the initial collection values to prevent user who logged out
	 * and logged back in from accessing incorrect data.
	 */
	private reset() {
		try {
			// setting to empty collection
			this.collection = collection(
				this.firestore,
				''
			) as CollectionReference<T, T>;
			// setting to empty query
			this.collectionQuery = query(this.collection);
		} catch {
			// suppress any errors
		}
	}

	/**
	 * Initializes the firestore service
	 */
	initialize(org: string) {
		if (this.collectionName && org) {
			this.collection = collection(
				this.firestore,
				`organizations/${org}/${this.collectionName}`
			) as CollectionReference<T, T>;

			this.collectionQuery = query(
				this.collection,
				where('deletedAt', '==', null)
			);
		} else {
			throw new Error('Needed data was not provided or setup properly');
		}
	}

	/**
	 * Finds the data based on the corresponding id
	 * @param id The id of the data to find
	 * @returns The found data
	 */
	findById(id: string, orgId?: string | null) {
		if (this.collectionName) {
			if (!orgId) {
				orgId = this.organizationId || localStorage.getItem('orgId');
			}

			return orgId
				? getDoc(doc(this.collection, id))
				: getDoc(
						doc(
							collection(
								this.firestore,
								this.collectionName
							) as CollectionReference<T>,
							id
						)
					);
		}
		throw new Error('Collection name has not been set');
	}

	/**
	 * Finds multiple records of data based on an array of ids provided
	 * @param ids Array of ids to find
	 * @param orgId the id of the organization to look in
	 * @returns an array of data
	 */
	findByIds(ids: string[], orgId?: string | null) {
		if (this.collectionName) {
			if (!orgId) {
				orgId = this.organizationId || localStorage.getItem('orgId');
			}

			return orgId
				? getDocs(query(this.collection, where('id', 'in', ids)))
				: getDocs(
						query(
							collection(
								this.firestore,
								this.collectionName
							) as CollectionReference<T>,
							where('id', 'in', ids)
						)
					);
		}
		throw new Error('Collection name has not been set');
	}

	/**
	 * Determines what data need to be updated with the new value, what data need
	 * to have the corresponding data deleted, and what items need to be left alone.
	 *
	 * @param before the value before the new changes
	 * @param after the value after the new changes
	 * @param value the document reference to update the new values with
	 * @param key the property to make the upsert against
	 */
	async upsert(
		before: DocumentReference<DocumentData>[] | null,
		after: DocumentReference<DocumentData>[],
		value: DocumentReference<DocumentData>,
		key: 'groups' | 'users' | 'products'
	) {
		const beforeMap = new Map<string, DocumentReference>();
		(before ?? []).forEach((item) => beforeMap.set(item.id, item));

		const afterMap = new Map<string, DocumentReference>();
		const updateMap = new Map<string, DocumentReference>();
		(after ?? []).forEach((item) => {
			afterMap.set(item.id, item);
			if (!beforeMap.has(item.id)) {
				updateMap.set(item.id, item);
			}
		});

		const deleteMap = new Map<string, DocumentReference>();
		beforeMap.forEach((item) => {
			if (!afterMap.has(item.id)) {
				deleteMap.set(item.id, item);
			}
		});

		const tasks: Promise<any>[] = [];
		updateMap.forEach((ref) =>
			tasks.push(
				updateDoc(ref, {
					[key]: arrayUnion(value)
				})
			)
		);
		deleteMap.forEach((ref) =>
			tasks.push(
				updateDoc(ref, {
					[key]: arrayRemove(value)
				})
			)
		);
		await Promise.all(tasks);
	}

	/**
	 * Creates a new entity with the provided information and returns
	 * that new entity.
	 *
	 * @param data information to create the entity with
	 * @returns the newly created entity
	 */
	create(data: Partial<T>) {
		const now = Timestamp.now();
		data.createdAt = now;
		data.updatedAt = now;
		data.deletedAt = null;
		try {
			return addDoc(
				this.collection,
				sanitize(data as T) // by now it shouldn't be a partial anymore
			).then((ref) =>
				updateDoc(ref, { id: ref.id } as UpdateData<T>).then(() => ref)
			);
		} catch (error) {
			console.error('There was an issue creating the entity', error);
			throw new Error('There was an issue creating the entity');
		}
	}

	/**
	 * Updates the the entity with the corresponding id with the provided data
	 *
	 * @param data The new data to update the entity document with
	 * @returns The newly updated entity
	 */
	update(data: UpdateData<Partial<T>>) {
		data.updatedAt = Timestamp.now();
		try {
			const ref = doc(this.collection, data.id as string);
			return updateDoc(ref, sanitize(data)).then(() => ref);
		} catch (error) {
			console.error(
				'There was an issue updating the entity in the Firestore',
				error
			);
			throw new Error('There wan issue updating the entity');
		}
	}

	/**
	 * Marks the entity as (soft) deleted by updating its record with a deleted timestamp
	 *
	 * @param id The id of the entity to be deleted
	 * @param data Any additional data to update the deleted object with.
	 * @returns An empty object
	 */
	async delete(id: string) {
		const now = Timestamp.now();
		try {
			return updateDoc(doc(this.collection, id), {
				deletedAt: now,
				updatedAt: now
			} as UpdateData<T>).then(() => ({}));
		} catch (error) {
			console.error(error);
			throw new Error('There was an issue deleting the entity');
		}
	}
}
