import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'stripHtmlTags'
})
export class StripHtmlTagPipe implements PipeTransform {
	transform(value: any): string {
		if (value === null || value === '') {
			return '';
		} else {
			value = value.toString();
		}

		// Regular expression to identify HTML tags in
		// the input string. Replacing the identified
		// HTML tag with a null string.
		return value.replace(/(<([^>]+)>)/gi, '');
	}
}
