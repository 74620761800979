/**
 * Roles given to auth user objects in the system.
 * Each auth user is either a regular user, or a super user
 */
export const AuthRoles = [
	/**
	 * Base user in the system
	 */
	'user',

	/**
	 * User with super permissions
	 */
	'super'
] as const;
export type AuthRole = typeof AuthRoles[number];

/**
 * Roles only given to users within the system.
 * Not applicable to auth user objects
 */
export const UserRoles = [
	/**
	 * User with admin permissions
	 */
	'admin',

	/**
	 * USer with owner level permissions in the organization
	 */
	'owner'
] as const;
export type UserRole = typeof UserRoles[number];

/**
 * Collection of all the roles in the system
 */
export const Roles = [...AuthRoles, ...UserRoles] as const;

/**
 * AuthRole and UserRole combined to use as a general role type. Any user in the system will
 * have a role contained in this type
 */
export type Role = typeof Roles[number];
