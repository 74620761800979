<p class="label" [title]="label">{{ label }}</p>
<div class="value">
	<ng-container *ngIf="!!value; else noValue">
		<app-hex-color [matTooltip]="value" [color]="value"></app-hex-color>
		<p [title]="value">{{ value | lowercase }}</p>
	</ng-container>
</div>

<ng-template #noValue>
	<p class="no-value" [title]="'form.shared.no-value' | translate">
		{{ 'form.shared.no-value' | translate }}
	</p>
</ng-template>
