<p class="primary margin-0 medium font-bold" [title]="title">
	{{ title }}
</p>

<ng-container *ngIf="description">
	<p
		class="margin-0 small"
		[class.text-danger]="descriptionError"
		[title]="description"
	>
		{{ description | stripHtmlTags }}
	</p>
</ng-container>

<ng-container *ngIf="!description">
	<p
		class="margin-0 small text-tertiary"
		[title]="'generic.no-description' | translate"
	>
		{{ 'generic.no-description' | translate }}
	</p>
</ng-container>
