import { Component, Inject } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-acknowledge-dialog',
	imports: [TranslateModule, MatDialogModule],
	templateUrl: 'acknowledge-dialog.component.html'
})
export class AcknowledgeDialogComponent {
	constructor(
		private readonly dialogRef: MatDialogRef<AcknowledgeDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public readonly data: { header: string; message: string }
	) {}

	acknowledge() {
		this.dialogRef.close();
	}
}
