import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { HexColorComponent } from '../hex-color/hex-color.component';

@Component({
	standalone: true,
	selector: 'app-hex-color-value',
	imports: [
		CommonModule,
		TranslateModule,
		QuillModule,
		HexColorComponent,
		MatTooltipModule
	],
	templateUrl: 'hex-color-value.component.html',
	styleUrls: ['hex-color-value.component.scss']
})
export class HexColorValueComponent {
	@Input() label!: string;
	@Input() value: string | undefined = undefined;
}
